<template>
    <div class="mb-50">
        <div class="list-z">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item>中标项目经理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="table-header-area">
            <div class="table-header">
                <span class="table-title">中标项目经理</span>
            </div>
        </div>
        <generic-table
            :hasSnCol="false"
            :get-data="getPageData"
            :columns="displayColumns2"
            :page-size="20"
            :hide-pagination="false"
            @linkAction="onLinkAction"
        ></generic-table>
    </div>
</template>

<script>
import GenericTable from "@/components/generic-table";
import { getTenderInvitationPage } from "@/api/aptitude";

export default {
    components: {
        GenericTable,
    },
    data() {
        return {
            displayColumns2: [
                {
                    label: "项目经理",
                    prop: "winningLeader",
                    minWidth: 25,
                    hasLink: true,
                },
                {
                    label: "中标项目",
                    prop: "projectName",
                    minWidth: 50,
                    hasLink: true,
                },
                { label: "中标时间", prop: "winningDate", minWidth: 25 },
            ],
        };
    },
    computed: {},
    methods: {
        async getPageData({ size, current }) {
            // 获取数据的 API 请求
            let params = {};
            let province = this.$route.query.province;
            if (province)
                params.province = province;
            const response = await getTenderInvitationPage({
                size,
                current,
                ...params
            });

            let records = response.data ? response.data.records : [];
            let total = response.data ? response.data.total : 0;
            return {
                data: records,
                total: total,
            };
        },
        onLinkAction(prop, row) {
            console.log("linkAction", prop, row);
            if (prop == "winningLeader") {
                this.$router.push({
                    path: "/personnel/detail/",
                    query: { code: row.winningLeaderCode },
                });
            } else if (prop == "projectName") {
                this.$router.push({
                    path: "/project/details/",
                    query: { prjnum: row.projectNo },
                });
            }
        },
    },
};
</script>
<style scoped>
.mb-50 {
    margin-bottom: 50px;
}
.list-z {
    text-align: left;
    font-weight: 500;
    color: #4e4e4e;
    line-height: 40px;
    margin-top: 35px;
}
.fs-18 {
    font-size: 18px;
}
.table-header-area {
    display: flex;
}
.table-header {
    width: 520px;
    height: 60px;
    background: linear-gradient(90deg, #1890ff 0%, #ffffff 100%);
    background-blend-mode: lighten;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 32px;
    padding-left: 20px;
    /* background-image: url(~@/assets/images/bjtsy/jx7771.png); */
    background-repeat: no-repeat;
    background-size: cover;
}

.table-title {
    width: 233px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 500;
    color: #ffffff;
    line-height: 60px;
}

.right-header {
    text-align: right;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-right: 20px;
    flex: 1;
}
.view-more {
    font-size: 20px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 500;
    color: #888888;
    line-height: 60px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
}

.view-more::after {
    content: "";
    background-image: url(~@/assets/images/more11941.png);
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
}
</style>
